import { render, staticRenderFns } from "./ChoiceRow.vue?vue&type=template&id=fbaca4dc&scoped=true"
import script from "./ChoiceRow.vue?vue&type=script&lang=js"
export * from "./ChoiceRow.vue?vue&type=script&lang=js"
import style0 from "./ChoiceRow.vue?vue&type=style&index=0&id=fbaca4dc&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fbaca4dc",
  null
  
)

export default component.exports