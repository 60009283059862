<template>
	<svg ref="canvas" width="100%" />
</template>

<script>
import * as d3 from 'd3';

export default {
	name: "InfluencerGraph",
	props: ["influencers"],
	data() {
		return {
			width: 0
		};
	},
	watch: {
		influencers: function () {
			this.loadData();
		}
	},
	mounted() {
		this.loadData();
	},
	methods: {
		loadData: async function () {
			this.width = this.$el.clientWidth;
			this.drawInfluencerGraph();
		},
		goToInfluencer: async function (influencer) {
			if (this.$services.hasKey("Driller")) {
				this.$router.push(`/influencer/${influencer.id}`)
			}
		},
		drawInfluencerGraph: async function () {
			if (this.influencers && this.influencers.length > 0) {
				const margin = { top: 22, right: 2, bottom: 0, left: 0 };
				const width = this.width - margin.left - margin.right;
				const barHeight = 25;
				const height = (this.influencers.length + 2) * barHeight;
				const maxValue = d3.max(this.influencers, inf => Math.abs(inf.score));
				const x = d3.scaleLinear().domain([maxValue * -1, maxValue]).range([0, width]);
				const chart = d3.select(this.$refs.canvas);
				chart.selectAll("*").remove();
				
				chart
					.style("height", height).append('g')
					.attr('transform', 'translate(' + margin.left + ',' + margin.top + ')').append('g');

				const xAxis = d3.axisTop(x);
				chart.append('g')
					.attr("class", "axisWhite")
					.call(xAxis);

				chart.selectAll()
					.data(this.influencers).enter()
					.append('rect')
					.attr('width', d => x(Math.abs(d.score)) - x(0))
					.attr('height', barHeight - 2)
					.attr('fill', d => d.score > 0 ? "#00b300" : "#e85252")
					.attr('x', d => d.score > 0 ? x(0) : x(d.score))
					.attr('y', (d, i) => i * barHeight + 5)
					.on("click", d => this.goToInfluencer(d));

				const textWidthCalc = d3.scaleLinear().domain([314, 508]).range([7, 14]); // From iOS 5 - 8 Plus
				chart.selectAll()
					.data(this.influencers).enter()
					.append('text')
					.html(d => `${d.name}: ${(d.score > 0 ? '+' : '') + d.score} (${d.numCorrect}/${d.numChoices}) `)
					.attr('fill', d => d.score > 0 ? "#00b300" : "#e85252")
					.attr('y', (d, i) => i * barHeight + 23)
					.attr('x', d => d.score > 0 ? 0 : x(0) + 3)
					.attr('font-size', barHeight - 5)
					.attr('font-weight', 600)
					.attr('textLength', d => d.name.length < textWidthCalc(this.width) ? 0 : this.width / 2 - margin.left - 7)
					.attr('lengthAdjust', "spacingAndGlyphs")
			}
		}
	}
}
</script>

<style scoped>
.axisWhite line {
	stroke: white;
}
.axisWhite path {
	stroke: white;
}
.axisWhite text {
	fill: white;
}
</style>
