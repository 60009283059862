<template>
	<div
		@click="goToChoice()"
		class="tileBox"
		:class="{'loser':(choice.settled==1 && choice.winningOptionID!=choice.expectation.optionID), 'winner':(choice.settled==1 && choice.winningOptionID==choice.expectation.optionID), 'unanswered': true, 'answered':choice.expectation.optionID, 'recent':choice.settled==1, 'noResult':choice.settled==-1}"
	>
		<!-- LEFT OPTION -->
		<table cellpadding="0" cellspacing="0">
			<tr>
				<td class="leftPreview">
					<div class="choiceImages">
						<!-- LEFT OPTION -->
						<div
							class="st-choiceOption st-leftSide"
							:class="{'st-chosen': (choice.settled==0 && confidence < 0) || (choice.settled==1 && choice.winningOptionID == choice.leftOptionID)}"
						>
							<div
								v-if="!choice.leftOption.actor.imageURL"
								class="st-optionBox st-noImage"
							>{{choice.leftOption.actor.name}}</div>
							<div v-if="choice.leftOption.actor.imageURL" class="st-optionBox">
								<img class="st-actorImage" :src="choice.leftOption.actor.imageURL" />
							</div>

							<img
								v-if="choice.leftOption.outcome=='fall'"
								class="st-leftQualifier"
								src="/img/minus-arrow.png"
							/>

							<div
								v-if="choice.leftOption.outcomeValue"
								class="st-leftOutcomeValue"
							>{{choice.leftOption.outcomeValue}}</div>
							<div class="st-resultBox">
								<img
									v-if="(choice.settled==1 && choice.expectation.optionID == choice.leftOptionID && choice.winningOptionID==choice.expectation.optionID)"
									src="/img/checkmark.png"
									class="st-resultImage"
								/>
								<img
									v-if="(choice.settled==1 && choice.expectation.optionID == choice.leftOptionID && choice.winningOptionID!=choice.expectation.optionID)"
									src="/img/redX.png"
									class="st-resultImage"
								/>
							</div>
						</div>
						<!-- RIGHT OPTION -->
						<div
							class="st-choiceOption"
							:class="{'st-chosen': (choice.settled==0 && confidence > 0) || (choice.settled==1 && choice.winningOptionID == choice.rightOptionID)}"
						>
							<div
								v-if="!choice.rightOption.actor.imageURL"
								class="st-optionBox st-noImage"
							>{{choice.rightOption.actor.name}}</div>
							<div v-if="choice.rightOption.actor.imageURL" class="st-optionBox">
								<img class="st-actorImage" :src="choice.rightOption.actor.imageURL" />
							</div>

							<img
								v-if="choice.rightOption.outcome=='rise'"
								class="st-rightQualifier"
								src="/img/plus-arrow.png"
							/>

							<div
								v-if="choice.rightOption.outcomeValue"
								class="st-rightOutcomeValue"
							>{{choice.rightOption.outcomeValue}}</div>
							<div class="st-resultBox">
								<img
									v-if="(choice.settled==1 && choice.expectation.optionID == choice.rightOptionID && choice.winningOptionID==choice.expectation.optionID)"
									src="/img/checkmark.png"
									class="st-resultImage"
								/>
								<img
									v-if="(choice.settled==1 && choice.expectation.optionID == choice.rightOptionID && choice.winningOptionID!=choice.expectation.optionID)"
									src="/img/redX.png"
									class="st-resultImage"
								/>
							</div>
						</div>
					</div>
					<div v-if="choice.settled!=0" class="choiceResult">{{choice.result}}</div>
					<!-- CONFIDENCE SLIDER -->
					<slider type="row" :color="'neutral'" :value="confidence" :locked="false" />
				</td>
				<td class="rightInfo">
					<!--Name and Notes-->
					<div class="choiceName">{{choice.name}}</div> <i style="font-size: smaller;">- {{ choice.cutoffTime | just_date }}</i>
					<div
						v-if="choice.expectation.score!=0"
						class="score"
						:class="{'bad':(choice.settled==1 && choice.winningOptionID!=choice.expectation.optionID), 'good':(choice.settled==1 && choice.winningOptionID==choice.expectation.optionID)}"
					>{{choice.expectation.score>0 ? "+":""}}{{choice.expectation.score}}</div>
					<div
						class="showNote"
						:class="{'before':shadeNotes && note.createdAt < choice.cutoffTime,'during':shadeNotes && note.createdAt > choice.cutoffTime && note.createdAt < choice.resultTime,'after':shadeNotes && note.createdAt > choice.resultTime}"
						v-for="note in choice.expectation.notes"
						v-bind:key="note.id"
					>
						<b>{{note.createdAt | tiny_date}}</b>
						{{note.note}}
					</div>
				</td>
			</tr>
		</table>
	</div>
</template>

<script>
import Slider from "@/components/Slider.vue";
export default {
	name: "ChoiceRow",
	components: {
		Slider
	},
	props: ["choice"],
	data() {
		return {
			confidence: 0,
			shadeNotes: false
		};
	},
	created() {
		this.loadData();
	},
	methods: {
		loadData: async function () {
			this.shadeNotes = this.$services.hasKey('Method');
			if (this.choice && this.choice.expectation) {
				this.confidence = this.choice.expectation.confidence * (this.choice.expectation.optionID == this.choice.leftOptionID ? -1 : 1);
			}
		},
		goToChoice() {
			this.$router.push(`/choice/${this.choice.id}`)
		}
	}
}

</script>

<style scoped>
.tileBox {
	margin: 2px;
	background: rgb(22, 22, 22);
	border-radius: 3px;
	vertical-align: top;
	min-height: 70px;
	padding: 1px;
	text-align: left;
	width: 97%;
	cursor: pointer;
}
.leftPreview {
	width: 112px;
	display: inline-block;
}
.rightInfo {
	vertical-align: top;
	width: 100%;
}
.winner {
	border-color: #77aa00;
}
.loser {
	border-color: #ff0000;
}
.choiceImages {
	width: 114px;
}
.unanswered {
	border-width: 2px;
	border-style: dotted;
}
.answered {
	border-style: dashed;
	border-width: 1px;
}
.recent {
	border-style: solid;
	border-width: 2px;
}
.noResult {
	border: 1px solid silver;
}
.st-choiceOption {
	display: inline-block;
	height: 45px;
	width: 54px;
	display: inline-block;
	background-color: #e6e6e6;
	vertical-align: top;
	position: relative;
	border-radius: 5px;
	text-align: center;
}
.st-optionBox {
	display: inline-block;
	font-size: 10px;
	text-align: center;
	color: black;
}
.st-actorImage {
	position: absolute;
	top: 0px;
	left: 0px;
	z-index: 1;
	height: 45px;
	width: 54px;
}
.st-noImage {
	padding: 3px;
	font-size: 11px;
	text-align: center;
	padding-top: 10%;
	color: black;
}
.st-leftSide {
	margin-right: 2px;
}
.st-leftQualifier {
	position: absolute;
	color: #c83232;
	left: 1px;
	bottom: 1px;
	height: 18px;
	width: 18px;
	z-index: 2;
}
.st-rightQualifier {
	position: absolute;
	color: green;
	right: 1px;
	bottom: 1px;
	height: 18px;
	width: 18px;
	z-index: 2;
}
.st-leftOutcomeValue {
	position: absolute;
	left: 0px;
	bottom: 0px;
	z-index: 2;
	color: lightyellow;
	background-color: black;
	font-size: 10px;
	font-weight: 600;
	border-radius: 0px 2px 0px 5px;
	border: 1px solid black;
	padding: 0px 1px 0px 1px;
}
.st-rightOutcomeValue {
	position: absolute;
	right: 0px;
	bottom: 0px;
	z-index: 2;
	color: lightyellow;
	background-color: black;
	font-size: 10px;
	font-weight: 600;
	border-radius: 2px 0px 5px 0px;
	border: 1px solid black;
	padding: 0px 1px 0px 1px;
}
.st-chosen {
	background-color: #d6f5d6;
}
.unchosen {
	background-color: #efeff5;
}
.choiceResult {
	text-align: center;
	width: 98px;
	margin: 3px 0px 1px 8px;
	font-size: 12px;
	background-color: #505050;
	color: white;
	border-radius: 3px;
}
.st-resultImage {
	position: absolute;
	left: 0px;
	top: 0px;
	z-index: 3;
	height: 45px;
	width: 54px;
}
.st-confidence {
	margin-top: 2px;
	height: 16px;
	background-color: #808080;
	border-radius: 10px;
}
.choiceName {
	margin-top: -20px;
	display: inline-block;
	font-size: 15px;
	border-bottom: 1px solid white;
}
.showNote {
	font-size: 12px;
	color: white;
	margin-top: 2px;
}
.score {
	display: inline-block;
	font-size: 22px;
	float: right;
	vertical-align: top;
}
.before {
	color: #bbbbbb;
}
.during {
	color: #dddddd;
}
.after {
	color: #ffffff;
}
</style>
