<template>
	<svg
		class="slider"
		ref="box"
		width="100%"
		:height="size.height"
		@touchstart="touchStart($event)"
		@touchmove="touchMove($event)"
		@mousedown="dragStart($event)"
		@mouseup="dragEnd($event)"
		@mousemove="dragMove($event)"
	>
		<rect
			:width="size.shadowWidth"
			:x="size.shadowX"
			:y="size.shadowY"
			:height="size.shadowHeight"
			:rx="size.shadowCornerRadius"
			:ry="size.shadowCornerRadius"
			:style="style.shadow"
		/>

		<line :x1="size.lineX" :y1="size.lineY" :x2="maxX" :y2="size.lineY" :style="size.lineStyle" />

		<circle
			:cx="buttonX"
			:cy="size.buttonY"
			:r="isDragging? size.buttonR2 : size.buttonR"
			:style="style.button"
		/>

		<text
			v-if="showValue"
			:x="buttonX-5"
			:y="(size.buttonY | 0)+3"
			:style="'font-size:9px;fill:black'"
		>{{newValue}}</text>

		<rect
			v-if="locked"
			:x="lockX"
			:y="size.lockY"
			:width="size.lockWidth"
			:height="size.lockWidth"
			:style="size.lockStyle"
			ry="2"
			rx="2"
		/>
	</svg>
</template>

<script>
export default {
	name: "Slider",
	props: ["type", "value", "color", "locked"],
	data() {
		return {
			isDragging: false,
			showValue: false,
			width: 0,
			screenX: 0,
			minX: 0,
			maxX: 0,
			buttonX: 0,
			lockX: 0,
			newValue: 0,
			style: {},
			size: {},
			styles: [{
				name: "confidence",
				shadow: "fill:#4d4d4d",
				button: "stroke:black;stroke-width:2;fill:#d6f5d6"
			}, {
				name: "neutral",
				shadow: "fill:rgba(221, 221, 221, .4)",
				button: "stroke:black;stroke-width:1;fill:#d6f5d6"
			}, {
				name: "good",
				shadow: "fill:rgba(0, 153, 51, .1)",
				button: "stroke:rgba(0, 0, 0, .4);stroke-width:1;fill:#00b300"
			}, {
				name: "bad",
				shadow: "fill:rgba(153, 0, 51, .1)",
				button: "stroke:rgba(0, 0, 0, .4);stroke-width:1;fill:#C83232"
			}],
			sizes: [{
				type: "confidence",
				minVal: -100,
				maxVal: 100,
				height: "36px",
				width: "100%",
				shadowX: 2,
				shadowY: 2,
				shadowWidth: "98%",
				shadowHeight: 34,
				shadowCornerRadius: 17,
				lineX: 18,
				lineY: 19,
				lineStyle: "stroke:rgba(0,0,0, .8);stroke-width:2",
				buttonY: 19,
				buttonR: 14,
				buttonR2: 16,
				lockY: 2,
				lockWidth: 32,
				lockStyle: "stroke:white;stroke-width:2;fill:none"
			}, {
				type: "influencer",
				minVal: 0,
				maxVal: 100,
				height: "22px",
				width: "120", // Calculate
				shadowX: 1,
				shadowY: 1,
				shadowWidth: "97%",
				shadowHeight: 21,
				shadowCornerRadius: 11,
				lineX: 11,
				lineY: 12,
				lineStyle: "stroke:rgba(0,0,0, .4);stroke-width:2",
				buttonY: 12,
				buttonR: 8,
				buttonR2: 9,
				lockY: 2,
				lockWidth: 20,
				lockStyle: "stroke:#000;stroke-width:1;fill:none"
			}, {
				type: "row",
				minVal: -100,
				maxVal: 100,
				height: "20px",
				width: "110", // Calculate
				shadowX: 1,
				shadowY: 2,
				shadowWidth: "96%",
				shadowHeight: 14,
				shadowCornerRadius: 7,
				lineX: 5,
				lineY: 9,
				lineStyle: "stroke:lightyellow;stroke-width:1",
				buttonY: 9,
				buttonR: 6,
				lockY: 2,
				lockWidth: 14.5,
				lockStyle: "stroke:#fff;stroke-width:1;fill:none"
			}]
		};
	},
	watch: {
		value: function () {
			this.loadData();
		},
		locked: function () {
			this.loadData();
		}
	},
	mounted() {
		this.loadData();
	},
	methods: {
		loadData: async function () {
			this.width = this.$el.clientWidth;
			this.screenX = this.$el.getBoundingClientRect().x;
			this.size = this.sizes.find(s => s.type == this.type);
			this.style = this.styles.find(s => s.name == this.color);
			this.minX = this.size.lineX;
			this.maxX = this.width - this.size.lineX - this.size.shadowX * 2;
			const confPctOfRange = (this.value - this.size.minVal) / (this.size.maxVal - this.size.minVal);
			this.buttonX = (this.maxX - this.minX) * confPctOfRange + this.minX;
			this.lockX = this.buttonX - this.size.buttonR - 2;
			// console.log("Width is ", this.width);
			// console.log("Value is ", this.value);
			// console.log("MinVal is ", this.size.minVal)
			// console.log("Pct of Range is ", confPctOfRange);
			// console.log("This style is", this.style.name)
			// console.log("ButtonX is ", this.buttonX);
		},
		touchStart(evt) {
			if (!this.locked) {
				this.isDragging = true;
				this.updateCalcs(evt.touches[0].clientX - this.screenX);
			}
		},
		touchMove(evt) {
			if (this.isDragging) {
				this.updateCalcs(evt.touches[0].clientX - this.screenX);
			}
		},
		dragStart(evt) {
			if (!this.locked) {
				this.isDragging = true;
				this.updateCalcs(evt.offsetX);
			}
		},
		dragMove(evt) {
			if (this.isDragging) {
				this.updateCalcs(evt.offsetX);
			}
		},
		dragEnd(evt) {
			if (this.isDragging) {
				this.isDragging = false;
				this.updateCalcs(evt.offsetX);
			}
		},
		updateCalcs(offsetX) {
			if (this.type != "row") {
				this.buttonX = Math.max(Math.min(this.maxX, offsetX), this.minX);
				const confPctOfRange = (this.buttonX - this.minX) / (this.maxX - this.minX);
				this.newValue = (this.size.maxVal - this.size.minVal) * confPctOfRange + this.size.minVal;
				this.newValue = Math.round(this.newValue);
				this.$emit('updated', this.newValue)
				// console.log("evt.offsetX is", evt.offsetX)
				// console.log("this.buttonX is", this.buttonX)
				// console.log("this.width is", this.width)
				// console.log("this.minX is", this.minX)
				// console.log("this.maxX is", this.maxX)
				// console.log("this.maxVal is", this.size.maxVal)
				// console.log("this.minVal is", this.size.minVal)
				// console.log("Pct clicked on is", confPctOfRange)
				// console.log("New Value is:", this.newValue)
			}
		}
	}
}
</script>


<style scoped>
.slider {
	width: 100%;
	margin-top: 2px;
}
.confidence {
	background-color: red;
	height: 35px;
}
.influencer {
	background-color: blue;
	height: 25px;
	border-radius: 10px;
}
.row {
	background-color: green;
	height: 14px;
	border-radius: 10px;
}
</style>
