<template>
	<div class="basic">
		<div class="title" style="vertical-align: top; font-size: xx-large;">
			<img style="background-color: #efeff5; padding: 2px; border-radius: 5px;" class="actorImage" :src="actor.imageURL" />
			{{ actor.name }}
		</div>
		<div
				 v-if="score.score"
				 class="bigScore right"
				 :class="{'good ': score.score >= 0, 'bad ': score.score < 0}">
			{{ (score.score > 0 ? "+" : "") + score.score }} ({{
				score.numCorrect
			}}/{{ score.numChoices }}) {{ score.percentage }}%
		</div>
		<div v-if="score.score">
			<div v-if="score.numChoices > 1">
				You've made {{ score.numChoices }} {{ score.numChoices > 1 ? 'choices' : 'choice' }} for {{ actor.useThe ? 'the ' : '' }} {{ actor.name }}
				since {{ choices[choices.length - 1].cutoffTime | just_date }} and were right about {{ score.numCorrect }} of them for a score of {{ score.score > 0 ? '+' : '' }}{{ score.score }}.

				<score-graph :scoreLines="scoreLines"></score-graph>
			</div>
			<div v-else>
				<div class="divider"></div>
				Once you've made at least a couple of choices about {{ actor.useThe ? 'the ' : '' }} {{ actor.name }}, you will see a graph of your results here.
			</div>
			<div v-if="influencers.length > 0">
				Your {{ score.numChoices }} {{ actor.name }} {{ score.numChoices > 1 ? 'choices' : 'choice' }} have had
				{{ influencers.length }} {{ influencers.length == 1 ? 'influencer' : 'influencers' }}. <b>{{ influencers[0].name }}</b> in
				{{ influencers[0].feedName }}
				is your best influencer for
				{{ actor.useThe ? 'the ' : '' }} {{ actor.name }} with a value of
				{{ influencers[0].score > 0 ? '+' : '' }}{{ influencers[0].score }}.
				Here's how {{ influencers.length == 1 ? 'it' : `your ${influencers.length} influencers` }} affected your choices for {{ actor.useThe ? 'the' : '' }} {{ actor.name }}:
				<div class="divider">{{ actor.name }} Influencers</div>

				<div class="infRow" v-for="graph in infGraphs" v-bind:key="graph.name">
					<div class="feedName" :style="{color: graph.color}">
						{{ graph.name }}
					</div>
					<div
							 class="feedScore right"
							 :class="{
				'good ': graph.score.score >= 0,
				'bad ': graph.score.score < 0,
			}">
						{{ graph.score.score }} ({{ graph.score.numCorrect }}/{{
				graph.score.numChoices
			}}) {{ graph.score.percentage }}%
					</div>
					<influencer-graph :influencers="graph.influencers"></influencer-graph>
				</div>
			</div>
			<div v-else>
				<div class="divider"></div>
					You haven't tracked any influencers for {{ actor.useThe ? 'the ' : '' }} {{ actor.name }} yet.
					Once you do, you'll be able to analyze the value they bring to your {{ actor.name }} choices.
			</div>
		</div>
		<div v-else>
			You don't have any results to show yet for {{ actor.name }}. Once you do, you will see the analysis of your choices here.<br /><br />
		</div>

		<div class="divider">{{ actor.name }} Choices</div>
		<choice-row
								:choice="choice"
								v-for="choice in choices"
								v-bind:key="choice.id"></choice-row>

		<div class="footer">
			<button class="goButton" @click="done()">Back</button>
		</div>
		<div class="footer-spacer" />
		<div class="bottom-spacer" />
	</div>
</template>

<script>
import ScoreGraph from "@/components/ScoreGraph";
import InfluencerGraph from "@/components/InfluencerGraph";
import ChoiceRow from "@/components/ChoiceRow";

export default {
	name: "Actor",
	components: {
		ChoiceRow,
		InfluencerGraph,
		ScoreGraph
	},
	data() {
		return {
			actor: {},
			score: {},
			graph: {},
			infGraphs: [],
			choices: [],
			influencers: [],
			scoreLines: []
		};
	},
	mounted() {
		this.loadData();
	},
	methods: {
		loadData: async function () {
			this.scoreLines = JSON.parse(localStorage.getItem(`actor-${this.$route.params.id}-score-lines`));
			this.choices = JSON.parse(localStorage.getItem(`actor-${this.$route.params.id}-choices`));
			this.actor = await this.$services.getOne('actors', this.$route.params.id);
			// console.log("Actor is", this.actor)
			this.score = await this.$services.get(`score?actorID=${this.actor.id}`);
			const allFeeds = await this.$services.get("feeds");
			const scoreFeeds = allFeeds.map(async (feed) => {
				feed.score = await this.$services.get(`score?actorID=${this.actor.id}&feedID=${feed.id}`);
				const scores = await this.$services.get(`score?actorID=${this.actor.id}&feedID=${feed.id}&daily`);
				if (scores.length > 0) {
					//Get the Influencers for their charts and descriptive text
					const data = await this.$services.get(`influencers/scored?actorID=${this.actor.id}&feedID=${feed.id}`);
					const feedInfluencers = data.filter(influencer => influencer.numChoices > 0);
					for (const inf of feedInfluencers) {
						if (this.influencers.findIndex(i => i.name == inf.name) == -1) {
							inf.feedName = feed.name;
							this.influencers.push(inf);
						}
					}
					this.influencers = this.influencers.sort((a, b) => +a.score < +b.score ? 1 : -1);
					console.log("Sorted influencers:", this.influencers);
					const infChart = {name: feed.name, color: feed.color, score: feed.score, influencers: feedInfluencers};
					this.infGraphs.push(infChart);
					this.infGraphs.sort((a, b) => a.name < b.name ? -1 : 1);
				}
				const retVal = {name: feed.name, color: feed.color, scores: scores};
				return retVal;
			});
			Promise.all(scoreFeeds).then(res => {
				this.scoreLines = res;
				localStorage.setItem(`actor-${this.$route.params.id}-score-lines`, JSON.stringify(this.scoreLines));
			})
			this.loadChoices();
		},
		loadChoices: async function () {
			this.choices = await this.$services.get(`choices/filtered?actorID=${this.actor.id}`);
			localStorage.setItem(`actor-${this.$route.params.id}-choices`, JSON.stringify(this.choices));
		},
		done() {
			this.$router.back();
		}
	}
}

</script>

<style scoped>
.actorImage {
	height: 25px;
}
</style>
